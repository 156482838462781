import { DqCard, DqCardContent } from "@decentriq/components";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  type HeatMapDatum,
  type HeatMapSerie,
  ResponsiveHeatMap,
} from "@nivo/heatmap";
import { groupBy } from "lodash";
import { memo, useMemo } from "react";
import { useDataLabContext } from "features/dataLabs";

const DemographicDistributionCharts: React.FC = () => {
  const {
    dataLab: { data: dataLab },
  } = useDataLabContext();
  const { palette } = useTheme();

  const { statistics: dataLabStatistics, demographicsDataset } = dataLab || {};
  const { demographics_distributions: demographicsDistributions } =
    dataLabStatistics || {};

  // Group data by age group value
  const groupedData = useMemo(
    () =>
      demographicsDataset
        ? groupBy(
            Object.entries(demographicsDistributions),
            ([segmentName]) => {
              const [ageGroup] = segmentName.split("||");
              return ageGroup;
            }
          )
        : [],
    [demographicsDistributions, demographicsDataset]
  );

  const allUsersChartData: HeatMapSerie<HeatMapDatum, {}>[] = useMemo(
    () =>
      Object.entries(groupedData).map(([ageGroup, segmentsData]) => ({
        data: segmentsData.map(([segment, values]) => {
          const [, gender] = segment.split("||");
          return {
            x: gender,
            y: (values as [number, number])[0],
          };
        }),
        id: ageGroup,
      })),
    [groupedData]
  );

  const effectiveUsersChartData: HeatMapSerie<HeatMapDatum, {}>[] = useMemo(
    () =>
      Object.entries(groupedData).map(([ageGroup, segmentsData]) => ({
        data: segmentsData.map(([segment, values]) => {
          const [, gender] = segment.split("||");
          return {
            x: gender,
            y: (values as [number, number])[1],
          };
        }),
        id: ageGroup,
      })),
    [groupedData]
  );

  return (
    <Grid columnSpacing={1} container={true} justifyContent="center">
      <Grid
        item={true}
        md={6}
        sx={{ height: "350px", textAlign: "center" }}
        xs={12}
      >
        <DqCard sx={{ height: "100%", width: "100%" }}>
          <DqCardContent sx={{ height: "100%" }}>
            <Box height="100%" width="100%">
              <Typography>Demographics distribution (All users)</Typography>
              <ResponsiveHeatMap
                axisBottom={{
                  legend: "Gender",
                  legendOffset: 32,
                  legendPosition: "middle",
                }}
                axisLeft={{
                  legend: "Age group",
                  legendOffset: -58,
                  legendPosition: "middle",
                  tickPadding: 5,
                  tickSize: 5,
                }}
                colors={{
                  colors: [
                    palette.chart.secondary.main,
                    palette.chart.primary.light,
                    palette.chart.primary.main,
                  ],
                  divergeAt: 0.5,
                  type: "diverging",
                }}
                data={allUsersChartData}
                emptyColor={grey[100]}
                inactiveOpacity={0.5}
                margin={{
                  bottom: 92,
                  left: 64,
                  top: 24,
                }}
                valueFormat=">-.2%"
              />
            </Box>
          </DqCardContent>
        </DqCard>
      </Grid>
      <Grid
        item={true}
        md={6}
        sx={{ height: "350px", textAlign: "center" }}
        xs={12}
      >
        <DqCard sx={{ height: "100%", width: "100%" }}>
          <DqCardContent sx={{ height: "100%" }}>
            <Box height="100%" width="100%">
              <Typography>
                Demographics distribution (Users with a matching ID)
              </Typography>
              <ResponsiveHeatMap
                axisBottom={{
                  legend: "Gender",
                  legendOffset: 32,
                  legendPosition: "middle",
                }}
                axisLeft={{
                  legend: "Age group",
                  legendOffset: -58,
                  legendPosition: "middle",
                  tickPadding: 5,
                  tickSize: 5,
                }}
                colors={{
                  colors: [
                    palette.chart.secondary.main,
                    palette.chart.primary.light,
                    palette.chart.primary.main,
                  ],
                  divergeAt: 0.5,
                  type: "diverging",
                }}
                data={effectiveUsersChartData}
                emptyColor={grey[100]}
                inactiveOpacity={0.5}
                margin={{
                  bottom: 92,
                  left: 64,
                  top: 24,
                }}
                valueFormat=">-.2%"
              />
            </Box>
          </DqCardContent>
        </DqCard>
      </Grid>
    </Grid>
  );
};

DemographicDistributionCharts.displayName = "DemographicDistributionCharts";

export default memo(DemographicDistributionCharts);
