import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { EmptyData } from "components";
import {
  DataLabDownloadValidationReportButton,
  DataLabStatistics,
  DataLabValidationAlerts,
  DataLabValidationButton,
  useDataLabContext,
} from "features/dataLabs";

const DataLabQualityStatistics: React.FC = () => {
  const {
    dataLab: { data: dataLab },
  } = useDataLabContext();
  return (
    <Box mt={1}>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mb={1}
      >
        <Typography variant="h6">Data quality statistics</Typography>
        <Box display="flex">
          <DataLabDownloadValidationReportButton />
          <DataLabValidationButton />
        </Box>
      </Box>
      <DataLabValidationAlerts />
      {dataLab?.statistics ? (
        <DataLabStatistics />
      ) : (
        <EmptyData
          iconSize="3x"
          primaryText="There are no quality statistics computed yet"
          secondaryText="Wait while we validate the datalab"
          sx={{ marginTop: 7 }}
        />
      )}
    </Box>
  );
};

DataLabQualityStatistics.displayName = "DataLabQualityStatistics";

export default memo(DataLabQualityStatistics);
