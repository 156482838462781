import { DqSidePanelTitle } from "@decentriq/components";
import { faTrashCan as faTrashCanRegular } from "@fortawesome/pro-regular-svg-icons";
import { Box, Typography } from "@mui/material";
import { useBoolean } from "ahooks";
import { memo } from "react";
import {
  DataLabDeleteDialog,
  DataLabStatusLabel,
  useDataLabContext,
} from "features/dataLabs";

interface DataLabDetailsToolbarProps {
  id: string;
  onClose: () => void;
}

const DataLabDetailsToolbar: React.FC<DataLabDetailsToolbarProps> = ({
  id,
  onClose,
}) => {
  const [
    isDeleteDialogOpen,
    { setTrue: openDeleteDialog, setFalse: closeDeleteDialog },
  ] = useBoolean(false);
  const {
    dataLab: { data: dataLab, loading },
  } = useDataLabContext();
  if (loading || !dataLab) return <></>;
  return (
    <>
      <DqSidePanelTitle
        ToolbarProps={{
          menuItems: [
            {
              dangerous: true,
              icon: faTrashCanRegular,
              label: "Delete datalab",
              onClick: openDeleteDialog,
            },
          ],
        }}
        onClose={onClose}
        separated={true}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flex: 1,
            gap: 1,
            overflow: "hidden",
          }}
        >
          <Typography
            fontWeight="fontWeightSemiBold"
            noWrap={true}
            variant="h6"
          >
            {!dataLab?.name ? "Loading datalab name..." : dataLab.name}
          </Typography>
          <DataLabStatusLabel isValidated={dataLab.isValidated} />
        </Box>
      </DqSidePanelTitle>
      <DataLabDeleteDialog
        id={id}
        onCancel={closeDeleteDialog}
        open={isDeleteDialogOpen}
      />
    </>
  );
};

DataLabDetailsToolbar.displayName = "DataLabDetailsToolbar";

export default memo(DataLabDetailsToolbar);
