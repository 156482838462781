import { testIds } from "@decentriq/utils";
import { FormControl, Grid, OutlinedInput, Typography } from "@mui/material";
import { memo } from "react";
import { useCreationWizardConfiguration } from "features/MediaInsightsDcr/contexts";

const DataRoomName: React.FC = () => {
  const { setDataRoomName, dataRoomName } = useCreationWizardConfiguration();
  return (
    <Grid container={true} item={true} mt={1} spacing={1}>
      <Grid item={true} sx={{ pb: 0 }} xs={12}>
        <Typography fontWeight="fontWeightSemiBold" variant="body1">
          1. Data clean room details
        </Typography>
      </Grid>
      <Grid item={true} xs={12}>
        <FormControl
          fullWidth={true}
          sx={{ height: "100%", position: "relative" }}
        >
          <OutlinedInput
            autoComplete="off"
            autoFocus={true}
            data-testid={
              testIds.mediaInsightsDcr.mediaInsightsDcrCreateCollaborationStep
                .insightDataRoomName
            }
            onChange={(event) => setDataRoomName(event.target.value)}
            placeholder="Data clean room name"
            required={true}
            sx={{ width: "350px" }}
            value={dataRoomName}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

DataRoomName.displayName = "DataRoomName";

export default memo(DataRoomName);
